import React from "react"
import clsx from "clsx"

import {
  ToastMessage,
  useMediaQuery,
  useTheme,
  FadeTransition,
} from "../_common"
import {
  TFavoriteAction,
  IUpdateFavoritesHandler,
  IRecentAction,
} from "../../hooks/myFavorites/types"
import useMyFavorites from "../../hooks/myFavorites/useMyFavorites"
import classes from "./MyExpertsUndo.module.scss"

interface MyExpertsUndoProps {
  withFooter?: boolean
}

// eslint-disable-next-line consistent-return
function getUndoHandler(
  undoAction: IRecentAction | null,
  updateFavorites: IUpdateFavoritesHandler
) {
  if (!undoAction) {
    return () => {}
  }

  return () =>
    updateFavorites(
      undoAction.id,
      undoAction.action === "remove",
      undoAction.listingNumber
    )
}

function getUndoHintMessage(action: TFavoriteAction | undefined): string {
  if (action === undefined) return ""

  switch (action) {
    case "add":
      return "Zu deinen Favoriten hinzugefügt."
    case "remove":
      return "Von den Favoriten entfernt."
    default:
      throw new Error("Undo action type is wrong")
  }
}

const MyExpertsUndo: React.FC<MyExpertsUndoProps> = ({
  withFooter = false,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { recentAction, updateFavorites } = useMyFavorites()

  return (
    <FadeTransition show={recentAction !== null && recentAction.enabledUndo}>
      <ToastMessage
        rootClassName={clsx(classes.toastMessage, {
          [classes.toastMessageMobile]: isMobile,
          [classes.withFooter]: withFooter,
        })}
        hintMessage={getUndoHintMessage(recentAction?.action)}
        buttonTitle="RÜCKGÄNGIG"
        onClick={getUndoHandler(recentAction, updateFavorites)}
        testID="undo-btn"
      />
    </FadeTransition>
  )
}

export default MyExpertsUndo
