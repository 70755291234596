import React, { useState, useCallback } from "react"
import { useTranslations } from "next-intl"
import clsx from "clsx"

import StatusIndicator from "../Experts/StatusIndicator"
import { Box, Skeleton, AdviqoLink, useMediaQuery, useTheme } from "../_common"
import FavoriteAdvisorsIcon from "../_common/QuesticoIcon/FavoriteAdvisorsIcon"
import { MyExpertsContext } from "./context/MyExpertsContext"
import MyExpertsContainerDesktop from "./MyExpertsContainerDesktop"
import { useFetchFavoriteExpertsStatus } from "./hooks/useFetchFavoriteExpertsStatus"
import classes from "./MyExpertsButton.module.scss"

interface IMyExpertsEntryProps {
  isTransparent: boolean
  className?: string
}

const MyExpertsButton: React.FC<IMyExpertsEntryProps> = ({
  isTransparent,
  className,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [myExpertsOpened, setMyExpertsOpened] = useState(false)
  const { countStatuses, status, loading } = useFetchFavoriteExpertsStatus()
  const translate = useTranslations()

  const iconButtonClasses = clsx(classes.iconButton, {
    [classes.isTransparent]: isTransparent,
    [classes.active]: myExpertsOpened,
  })

  const handleFavoritesClose = useCallback(() => {
    setMyExpertsOpened((myExpertsOpened) => !myExpertsOpened)
  }, [])

  return (
    <>
      <Box className={clsx(classes.buttonWrapper, className)}>
        <AdviqoLink
          data-testid="my-experts-nav-link"
          to="/my-advisors"
          className={iconButtonClasses}
          onClick={(e) => {
            if (!isMobile) {
              e.preventDefault()
              handleFavoritesClose()
            }
          }}
        >
          <FavoriteAdvisorsIcon className={classes.iconLabelClassName} />
        </AdviqoLink>
        {loading && (
          <Box className={classes.statusPlaceholder}>
            <Skeleton variant="circular" width={16} height={16} />
          </Box>
        )}
        {status && (
          <StatusIndicator
            className={classes.statusIndicator}
            onlineStatus={status}
          >
            {countStatuses?.online || ""}
          </StatusIndicator>
        )}
      </Box>
      {myExpertsOpened && (
        <Box className={classes.myExperts}>
          <MyExpertsContext.Provider value={{ handleFavoritesClose }}>
            <MyExpertsContainerDesktop
              headline={translate("favoriteExpertsTitle")}
              tabsVariant="fullWidth"
            />
          </MyExpertsContext.Provider>
        </Box>
      )}
    </>
  )
}

export default MyExpertsButton
