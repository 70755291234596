"use client"

import React from "react"

import clsx from "clsx"
import { Box, useTheme, useMediaQuery } from "../_common"
import ChevronIcon from "../_common/QuesticoIcon/ChevronIcon"
import MyExpertsCollection from "./MyExpertsCollection"
import { IContainerFooterLinkProps, TListing } from "./types"
import { TScrollVariant } from "../TabBar/types"
import MyExpertsContainerFooter from "./MyExpertsContainerFooter"
import MyExpertsUndo from "./MyExpertsUndo"
import classes from "./MyExpertsContainer.module.scss"
import { useTranslations } from "next-intl"
import { useRouter } from "next/navigation"

interface IMyExpertsContainerProps {
  listing: TListing
  footerLink?: IContainerFooterLinkProps
  /**
   * This prop serve as fix for snapshot testing
   * <Tabs/> failed snapshot testing if it's scrollable
   * @link https://github.com/mui-org/material-ui/issues/10783
   */
  isScrollable?: boolean
}

function getTabsVariant(
  isMobile: boolean,
  isScrollable: boolean
): TScrollVariant {
  if (!isScrollable) return "fullWidth"

  return isMobile ? "fullWidth" : "scrollable"
}

const MyExpertsContainer: React.FC<IMyExpertsContainerProps> = ({
  listing,
  footerLink,
  isScrollable = true,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const translate = useTranslations()
  const router = useRouter()
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box
          className={classes.backIcon}
          onClick={() => router.back()}
          data-testid="my-experts-back-link"
        >
          <ChevronIcon direction="left" />
        </Box>
        <Box component="h2" className={classes.headerText}>
          {translate("favoriteExpertsTitle")}
        </Box>
      </Box>
      <MyExpertsCollection
        className={clsx(classes.tabsWrapper, {
          [classes.tabsWrapperWithFooter]: !!footerLink,
        })}
        tabsVariant={getTabsVariant(isMobile, isScrollable)}
        tabBodyClassName={clsx(classes.tabBody)}
        listing={listing}
      />
      {isMobile && (
        <>
          <MyExpertsUndo withFooter={!!footerLink} />
          {footerLink && (
            <MyExpertsContainerFooter
              {...footerLink}
              buttonClassName={classes.footerLink}
              footerClassName={classes.footerClass}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default MyExpertsContainer
