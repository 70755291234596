"use client"

import React, { useContext } from "react"
import { usePathname } from "next/navigation"
import { Box, ClickAwayListener } from "../_common"
import CloseIcon from "../_common/QuesticoIcon/CloseIcon"
import { MyExpertsContext } from "./context/MyExpertsContext"
import MyExpertsCollection from "./MyExpertsCollection"
import { DEFAULT_PLACEHOLDER_SIZE_DESKTOP } from "./constants"
import { TScrollVariant } from "../TabBar/types"
import { IContainerFooterLinkProps } from "./types"
import MyExpertsContainerFooter from "./MyExpertsContainerFooter"
import { hasRecommendationsPath } from "./utils"
import MyExpertsUndo from "./MyExpertsUndo"
import classes from "./MyExpertsContainerDesktop.module.scss"
import clsx from "clsx"

interface IMyExpertsContainerDesktopProps {
  headline: string
  tabsVariant: TScrollVariant
  footerLink?: IContainerFooterLinkProps
}

const MyExpertsContainerDesktop: React.FC<IMyExpertsContainerDesktopProps> = ({
  headline,
  tabsVariant,
  footerLink,
}) => {
  const pathname = usePathname()
  const shouldFooterRender = !hasRecommendationsPath(pathname) && !!footerLink
  const { handleFavoritesClose } = useContext(MyExpertsContext)
  const tabBodyClasses = clsx(classes.tabBody, {
    [classes.shouldFooterRender]: shouldFooterRender,
  })

  return (
    <ClickAwayListener onClickAway={() => handleFavoritesClose()}>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Box className={classes.headerText}>{headline}</Box>
          <Box
            className={classes.closeIcon}
            onClick={() => handleFavoritesClose()}
          >
            <CloseIcon />
          </Box>
        </Box>
        <MyExpertsCollection
          listing="my-advisors"
          tabsVariant={tabsVariant}
          tabBodyClassName={tabBodyClasses}
          placeholderSize={DEFAULT_PLACEHOLDER_SIZE_DESKTOP}
        />
        <MyExpertsUndo withFooter={shouldFooterRender} />
        {shouldFooterRender && <MyExpertsContainerFooter {...footerLink} />}
      </Box>
    </ClickAwayListener>
  )
}

export default MyExpertsContainerDesktop
