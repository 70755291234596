import React from "react"

import { useBrowserFeatures } from "../hooks/browserFeatures"
import {
  IContentItem,
  IImageFormatContentful,
} from "../graphql/contentful-schema"
import { useGetContentItemListQuery } from "../graphql/contentfulHooks"
import OneExpertList from "../ui-lib/MyExperts/OneExpertList"
import { getContentfulEntriesIDs } from "../graphql/constants"
import { useRuntimeConfig } from "../hooks/runtimeConfig"

interface IOneExpertListProps {
  categories?: IContentItem[]
  isExtended?: boolean
}

const OneExpertListContainer: React.FC<IOneExpertListProps> = ({
  categories,
  isExtended,
}) => {
  const { isFeatureSupported } = useBrowserFeatures()
  const { getRuntimeConfig } = useRuntimeConfig()
  const { ONE_EXPERT_ITEM_LIST } = getContentfulEntriesIDs(getRuntimeConfig)

  const { data } = useGetContentItemListQuery({
    variables: {
      id: ONE_EXPERT_ITEM_LIST,
      format: isFeatureSupported("webp")
        ? IImageFormatContentful.WebpContentful
        : IImageFormatContentful.JpgContentful,
    },
    skip: categories !== undefined,
  })

  const items = categories || data?.contentItemList.itemsCollection.items

  return <OneExpertList items={items} isExtended={isExtended} />
}

export default OneExpertListContainer
