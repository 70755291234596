import React, { useCallback, useState } from "react"

import clsx from "clsx"
import { useAuthentication } from "../../../../../hooks/authentication"
import HeartIcon from "../../../../_common/QuesticoIcon/HeartIcon"
import HeartFilledIcon from "../../../../_common/QuesticoIcon/HeartFilledIcon"
import ActionButton from "./ActionButton"
import useMyFavorites from "../../../../../hooks/myFavorites/useMyFavorites"
import classes from "./FavoriteButton.module.scss"

interface IFavouriteButtonContainerProps {
  listingNumber: string
  id: number
  isFavorite: boolean
  buttonClassName?: string
  testId?: string
  withUndo?: boolean
}

interface IFavouriteButtonProps {
  onClick: React.MouseEventHandler
  isFavourite: boolean
  disabled?: boolean
  buttonClassName?: string
  testId?: string
}

export const FavouriteButton: React.FC<IFavouriteButtonProps> = ({
  onClick,
  isFavourite,
  disabled = false,
  buttonClassName,
  testId = "favouriteButton",
}) => {
  return (
    <ActionButton
      testId={testId}
      buttonClassName={clsx({ [classes.noClick]: disabled }, buttonClassName)}
      onClick={onClick}
      aria-label={isFavourite ? "Remove from Favourites" : "Add to Favourites"}
      icon={isFavourite ? <HeartFilledIcon /> : <HeartIcon />}
    />
  )
}

const FavouriteButtonContainer: React.FC<IFavouriteButtonContainerProps> = ({
  listingNumber,
  id,
  // isFavorite,
  buttonClassName,
  testId,
  withUndo = false,
}) => {
  const { isAuthenticated, goToAuth } = useAuthentication()
  const { checkIsFavourite, updateFavorites } = useMyFavorites()
  const isFavorite = checkIsFavourite(id)

  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const onClick = useCallback(async () => {
    if (!isAuthenticated) {
      goToAuth()
    } else if (isAuthenticated) {
      setButtonDisabled(true)
      await updateFavorites(id, !isFavorite, listingNumber, withUndo)
      setButtonDisabled(false)
    }
  }, [isAuthenticated, goToAuth, updateFavorites, id, isFavorite])

  return (
    <FavouriteButton
      onClick={onClick}
      isFavourite={isFavorite}
      disabled={isButtonDisabled}
      buttonClassName={buttonClassName}
      testId={testId}
    />
  )
}

export default FavouriteButtonContainer
