import React, { useContext, useCallback } from "react"
import clsx from "clsx"
import { useContainerQuery } from "react-container-query"

import { IExpert, MouseEventButton, IAdvisorLabels } from "../../types"
import { buildProfileUrl } from "../../utils/slugUtils"
import { useRuntimeConfig } from "../../hooks/runtimeConfig"
import { Box, AdviqoLink } from "../_common"
import Avatar from "../Experts/Avatar"
import ProductPrice from "../Experts/ProductPrice"
import ProductsList from "../Experts/Products/ProductsList"
import HeartFilledIcon from "../_common/QuesticoIcon/HeartFilledIcon"
import { StarRating } from "../Experts/Rating"
import { MyExpertsContext } from "./context/MyExpertsContext"
import HeartIcon from "../_common/QuesticoIcon/HeartIcon"
import ActionButton from "../Experts/ExpertProfile/ProfileHero/Actions/ActionButton"
import Tags from "../Experts/Tags"
import { useTracking } from "../../hooks/tracking"
import { trackProductClick, getMouseClick } from "../Experts/utils"
import { TMyExpertListing } from "./types"
import useMyFavorites from "../../hooks/myFavorites/useMyFavorites"
import FavouriteButtonContainer from "../Experts/ExpertProfile/ProfileHero/Actions/FavouriteButton"
import classes from "./ExpertEntry.module.scss"

interface IMyExpertEntryProps extends IExpert {
  listing?: TMyExpertListing
  withRating?: boolean
  withProducts?: boolean
  withFavouriteButton?: boolean
  withUndo?: boolean
  withTags?: boolean
  withAddFavorite?: boolean
  isFavOrRecentPage?: boolean
  className?: string
}
const query = {
  widthLargerThan600: {
    minWidth: 600,
  },
}

const hasLabels = (labels: IAdvisorLabels) =>
  Object.values(labels).some((val) => val)

const ExpertEntry: React.FC<IMyExpertEntryProps> = (expert) => {
  const {
    id,
    listing_number: listingNumber,
    avatar,
    name,
    rating,
    products,
    labels,
    status,
    // contactDateTime,
    withRating = true,
    className,
    listingName,
    withProducts,
    withFavouriteButton,
    withUndo = true,
    withTags,
    withAddFavorite,
    promotion,
  } = expert

  const [containerParams, containerRef] = useContainerQuery(query, {})

  const link = buildProfileUrl(name, listingNumber)
  const { getRuntimeConfigByKey } = useRuntimeConfig()

  const { updateFavorites, checkIsFavourite } = useMyFavorites()
  const isFavorite = checkIsFavourite(id)
  const { handleFavoritesClose } = useContext(MyExpertsContext)

  const iconClassName = clsx(classes.icon, {
    [classes.iconWithRating]: withRating,
  })

  const avatarClassName = clsx(classes.avatarWrapper, {
    [classes.avatarWrapperCover]: withAddFavorite && withTags,
  })

  const productsListClasses = clsx(classes.productsList, {
    [classes.widthLargerThan600]: Boolean(containerParams.widthLargerThan600),
  })

  const handleFavoriteUpdate = useCallback(() => {
    const newIsFavorite = !isFavorite
    updateFavorites(id, newIsFavorite, listingNumber, withUndo)
  }, [updateFavorites, id, isFavorite, listingNumber, withUndo])

  const { trackEvent, setProperties } = useTracking()

  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      trackProductClick(
        getMouseClick(e),
        expert,
        listingName,
        trackEvent,
        getRuntimeConfigByKey("MANDATOR_NUMBER")
      )

      setProperties({
        listingName,
      })

      if (e.button !== MouseEventButton.SECONDARY) {
        handleFavoritesClose()
      }
    },
    [
      expert,
      listingName,
      trackEvent,
      getRuntimeConfigByKey,
      setProperties,
      handleFavoritesClose,
    ]
  )

  return (
    <div className={clsx(classes.root, className)} ref={containerRef}>
      <AdviqoLink
        underline="none"
        to={link}
        onClick={clickHandler}
        onContextMenu={clickHandler}
        className={avatarClassName}
      >
        <Avatar
          photo={avatar}
          displayName={name}
          onlineStatus={status}
          onlineStatusClassName={classes.onlineStatus}
        />
      </AdviqoLink>
      <Box className={classes.detailsCol}>
        <AdviqoLink
          className={classes.detailsLink}
          underline="none"
          to={link}
          onClick={clickHandler}
          onContextMenu={clickHandler}
        >
          <Box
            component="h3"
            className={classes.expertName}
            data-testid="expert-name"
          >
            {name}
          </Box>

          {withRating && (
            <Box className={classes.metaWrapper}>
              {withTags && hasLabels(labels) && (
                <Box className={classes.tagsWrapper}>
                  <Tags tags={labels} tagClassName={classes.tagClassName} />
                </Box>
              )}
              <Box className={classes.rating}>
                <StarRating
                  rating={rating}
                  withRatingValue={false}
                  className={classes.ratingItems}
                />
              </Box>
            </Box>
          )}

          <ProductPrice
            rootClassName={classes.customPrice}
            promotion={promotion}
            products={products}
            promotionVariation="minimal-dense"
            promoBreakLine
          />
        </AdviqoLink>
        {withFavouriteButton && (
          <FavouriteButtonContainer
            listingNumber={listingNumber}
            id={id}
            isFavorite={isFavorite}
            buttonClassName={iconClassName}
            testId="add-remove-expert"
            withUndo={withUndo}
          />
        )}
      </Box>

      {withProducts && (
        <ProductsList
          promotion={promotion}
          products={products}
          listingNo={listingNumber}
          photo={avatar}
          displayName={name}
          rootClassName={productsListClasses}
          withLabel={containerParams.widthLargerThan600}
          advisorStatus={status}
          initSrc={listingName}
        />
      )}

      {withAddFavorite && (
        <ActionButton
          testId="add-recommended-expert-to-fav"
          buttonClassName={classes.updateFavouritesAction}
          onClick={handleFavoriteUpdate}
          icon={
            <Box className={classes.updateFavouritesIcon}>
              <Box component="span">+</Box>
              {isFavorite ? <HeartFilledIcon /> : <HeartIcon />}
            </Box>
          }
        />
      )}
    </div>
  )
}

export default ExpertEntry
