import React from "react"
import clsx from "clsx"

import { Box, AdviqoButton } from "../_common"
import AdvisorIcon from "../_common/QuesticoIcon/AdvisorIcon"
import { IContainerFooterLinkProps } from "./types"
import classes from "./MyExpertsContainerFooter.module.scss"

const MyExpertsContainerFooter: React.FC<IContainerFooterLinkProps> = ({
  title,
  to,
  buttonClassName,
  footerClassName,
}) => {
  return (
    <Box className={clsx(classes.footer, footerClassName)}>
      <Box className={classes.buttonWrapper}>
        <AdviqoButton
          testId="my-experts-footer-link"
          variant="outlined"
          icon={<AdvisorIcon />}
          label={title}
          buttonClassName={clsx(classes.button, buttonClassName)}
          iconClassName={classes.iconClassName}
          labelClassName={classes.buttonText}
          fullWidth
          data-testid="my-advisors-footer-button"
          to={to}
        />
      </Box>
    </Box>
  )
}

export default MyExpertsContainerFooter
