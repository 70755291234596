import React, { useCallback, useEffect } from "react"

import { useRuntimeConfig } from "../../hooks/runtimeConfig"
import { mapExpertsToClientTrackingTrack } from "../Experts/utils"
import ExpertsList from "./ExpertsList"
import { IMyExpertListProps } from "./types"
import {
  markAnimationAsViewed,
  hasSeenAnimation,
  getRecentItems,
} from "./utils"
import { useFetchExperts } from "../Experts/ExpertsService"
import { useAuthentication } from "../../hooks/authentication"

const listingName = "Expert List Navbar Recent"

const RecentExpertsConnected: React.FC<IMyExpertListProps> = ({
  listing,
  size,
  placeholderSize,
  emptyState,
  errorState,
  isExtended,
  withPagination,
  withProducts,
  withFavouriteButton,
  withUndo,
}) => {
  const { getRuntimeConfigByKey } = useRuntimeConfig()

  const { userId } = useAuthentication()
  const recentExpertIds = getRecentItems(userId)

  const {
    experts: expertsListing,
    loadMore,
    loading,
    error,
    currentPageNumber,
  } = useFetchExperts(
    "recentExpertsListForMyExpertsDropdown",
    { size, ids: recentExpertIds.map((i) => i.id) },
    recentExpertIds.length === 0
  )

  const getClientTrackingProperties = useCallback(
    (experts: any) => ({
      products: mapExpertsToClientTrackingTrack(
        experts,
        getRuntimeConfigByKey("MANDATOR_NUMBER"),
        {
          rating: true,
          tag: true,
        }
      ),
      list_id: listingName,
    }),
    [getRuntimeConfigByKey]
  )

  useEffect(() => {
    return () => {
      markAnimationAsViewed()
    }
  }, [])

  if (!hasSeenAnimation()) {
    return <>{emptyState}</>
  }

  return (
    <ExpertsList
      listing={listing}
      loadMore={loadMore}
      withPagination={!!isExtended && !expertsListing.isLast && withPagination}
      currentPageNumber={currentPageNumber}
      placeholderSize={placeholderSize}
      loading={loading}
      expertsListing={expertsListing}
      emptyState={!error && emptyState}
      errorState={!!error && errorState}
      isExtended={isExtended}
      getClientTrackingProperties={getClientTrackingProperties}
      listingName={listingName}
      withProducts={withProducts}
      withFavouriteButton={withFavouriteButton}
      withUndo={withUndo}
    />
  )
}

export default RecentExpertsConnected
