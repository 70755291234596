import React, { useCallback } from "react"
import { useTranslations } from "next-intl"

import TabBar from "../TabBar"
import { useTheme, useMediaQuery } from "../_common"
import { TListing } from "./types"
import { getTaBarItems } from "./collections"
import { TScrollVariant } from "../TabBar/types"
import useMyFavorites from "../../hooks/myFavorites/useMyFavorites"
import classes from "./MyExpertsCollection.module.scss"

interface IMyExpertsCollectionProps {
  listing: TListing
  tabsVariant: TScrollVariant
  className?: string
  tabBodyClassName?: string
  placeholderSize?: number
}

const MyExpertsCollection: React.FC<IMyExpertsCollectionProps> = ({
  listing,
  tabsVariant,
  className,
  tabBodyClassName,
  placeholderSize,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const isExtended = !isMobile && tabsVariant === "scrollable"
  const { recentAction, resetRecentAction } = useMyFavorites()
  const translate = useTranslations()

  const onTabChange = useCallback(() => {
    if (recentAction) resetRecentAction()
  }, [recentAction, resetRecentAction])

  return (
    <TabBar
      className={className}
      tabBodyClassName={tabBodyClassName}
      tabClassName={classes.tabClassName}
      items={getTaBarItems(
        listing,
        isExtended,
        translate("favouriteTabTitle"),
        placeholderSize
      )}
      isExtended={isExtended}
      tabsVariant={tabsVariant}
      onTabChange={onTabChange}
      withHash={false}
    />
  )
}

export default MyExpertsCollection
