import React, { useState } from "react"
import clsx from "clsx"

import { Box } from "../_common"
import TabsHeader from "./TabsHeader"
import { ITabBarProps, ITabsList } from "./types"
import { getTabId } from "./utils"
import classes from "./TabBar.module.scss"

const getInitialTabValue = (items: ITabsList[]) => {
  const activeIndex = items.findIndex((tab) => Boolean(tab.isActive))
  const activeIndexFinal = activeIndex !== -1 ? activeIndex : 0

  return items[activeIndexFinal]?.value || ""
}

const TabBar: React.FC<ITabBarProps> = ({
  items = [],
  className,
  tabBodyClassName,
  tabClassName,
  isExtended = false,
  tabsVariant,
  onTabChange,
  withHash,
}) => {
  const [tabValue, setTabValue] = useState<string>(getInitialTabValue(items))

  return (
    <Box className={className}>
      <TabsHeader
        items={items}
        tabsVariant={tabsVariant}
        tabValue={tabValue}
        setTabValue={setTabValue}
        onTabChange={onTabChange}
        withHash={withHash}
        tabClassName={tabClassName}
        tabsClassName={classes.tabsClassName}
        rootClassName={clsx({
          [classes.headerRootExtended]: isExtended,
        })}
      />
      {items.map(({ value, content }) =>
        tabValue === value ? (
          <Box
            key={value}
            role="tabpanel"
            id={getTabId(value)}
            aria-labelledby={`advii-tab-${value}`}
            className={tabBodyClassName}
          >
            {content}
          </Box>
        ) : null
      )}
    </Box>
  )
}

export default TabBar
