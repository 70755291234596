import React from "react"
import clsx from "clsx"
import { AdviqoButton } from "../../../../_common"
import { IAdviqoButtonProps } from "../../../../_common/AdviqoButton/AdviqoButton"
import classes from "./ActionButton.module.scss"

type IActionButtonProps = Pick<
  IAdviqoButtonProps,
  "icon" | "disabled" | "onClick" | "testId" | "buttonClassName"
>

const ActionButton: React.FC<IActionButtonProps> = (props) => {
  const { buttonClassName } = props

  return (
    <AdviqoButton
      {...props}
      round
      buttonClassName={clsx(classes.button, buttonClassName)}
    />
  )
}

export default ActionButton
