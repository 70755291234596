import React, { useCallback } from "react"
import clsx from "clsx"

import { TExpertEntry } from "../../types"
import StaticList from "../Experts/StaticList"
import ExpertEntry from "./ExpertEntry"
import ExtendedExpertEntry from "../Experts/ExpertEntry"
import { Box, CircularProgress, InfiniteScroll } from "../_common"
import { getTabId } from "../TabBar/utils"
import { IExperts } from "../Experts/ExpertsService/hooks/types"
import { IMyExpertListProps } from "./types"
import { getListingName } from "./utils"
import classes from "./ExpertsList.module.scss"

interface IExpertsListProps extends Omit<IMyExpertListProps, "size"> {
  expertsListing: IExperts
  loading: boolean
  loadMore: (nextPage: number) => void
  currentPageNumber: number
  getClientTrackingProperties?: (
    content: TExpertEntry[]
  ) => Record<string, unknown>
  headlineText?: string
  withRating?: boolean
}

const ExpertsList: React.FC<IExpertsListProps> = ({
  listing,
  expertsListing,
  loading,
  emptyState,
  withPagination,
  errorState,
  placeholderSize,
  loadMore,
  withRating = true,
  currentPageNumber,
  isExtended = false,
  headlineText,
  getClientTrackingProperties,
  listingName,
  withProducts,
  withFavouriteButton,
  withTags,
  withAddFavorite,
  withUndo,
}) => {
  const next = useCallback(() => {
    loadMore(currentPageNumber + 1)
  }, [currentPageNumber, loadMore])

  const listingNameFinal = listingName || getListingName(listing)

  const list = (
    <StaticList
      getClientTrackingProperties={getClientTrackingProperties}
      content={expertsListing?.content}
      isLoading={loading}
      withPagination={withPagination}
      onLoadMoreClick={loadMore}
      nextPageNumber={currentPageNumber + 1}
      isFavoriteExperts
      placeholderSize={placeholderSize}
      listItemClassName={clsx({
        [classes.expertEntry]: !isExtended,
      })}
      emptyState={emptyState}
      customErrorSection={errorState}
      withTransition
      renderItem={(expert) =>
        isExtended ? (
          <ExtendedExpertEntry expert={expert} listingName={listingNameFinal} />
        ) : (
          <ExpertEntry
            {...expert}
            withRating={withRating}
            isFavOrRecentPage
            listingName={listingNameFinal}
            withProducts={withProducts}
            withFavouriteButton={withFavouriteButton}
            withTags={withTags}
            withAddFavorite={withAddFavorite}
            withUndo={withUndo}
          />
        )
      }
    />
  )

  return (
    <>
      {headlineText && (
        <Box component="span" className={classes.listHeadline}>
          {headlineText}
        </Box>
      )}
      {!isExtended ? (
        <InfiniteScroll
          dataLength={expertsListing?.content.length}
          next={next}
          hasMore={!expertsListing?.isLast}
          loader={
            // Don't show loader on initial load,
            // when container is empty
            loading && expertsListing?.content.length ? (
              <Box className={classes.loader}>
                <CircularProgress thickness={3} size={20} color="primary" />
              </Box>
            ) : null
          }
          /**
           * scrollableTarget does not work properly with React.RefObject,
           * getTabId(listing) - return id of the DOM element,
           * which we would use instead of React.RefObject
           */
          scrollableTarget={getTabId(listing)}
        >
          {list}
        </InfiniteScroll>
      ) : (
        list
      )}
    </>
  )
}

export default ExpertsList
