import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"
import classes from "./HeartFilledIcon.module.scss"

const HeartFilledIcon: React.FC<IIconProps> = ({ color = "currentColor" }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={classes.root}>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.544 5.46228C18.5943 3.51257 15.4423 3.51257 13.4926 5.46228L11.979 6.97594L10.5089 5.50588C8.56544 3.56241 5.4073 3.56241 3.4576 5.50588C1.51413 7.45558 1.51413 10.6075 3.4576 12.5572L4.92766 14.0273L11.979 21.0786L20.5377 12.5198C22.4874 10.5701 22.4874 7.4182 20.5377 5.4685L20.544 5.46228Z"
            fill={color}
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.9 2C14.3 2 12.9 2.7 11.8 3.8C10.7 2.6 9.3 2 7.7 2C4.6 2 2 4.6 2 7.9C2 14.1 10.4 21.5 11.8 21.5C13.2 21.5 21.6 14.1 21.6 7.9C21.6 4.7 19 2 15.9 2Z"
            fill={color}
          />
        </svg>
      )}
    </IconBase>
  )
}

export default HeartFilledIcon
