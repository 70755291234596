import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const FavoriteAdvisorsIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  className,
}) => {
  const { isQuesticoBrand } = useCheckBrand()

  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 19 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.3598 13.6C14.1043 13.6809 13.8593 13.7917 13.6298 13.93C13.3964 14.0669 13.1755 14.2242 12.9698 14.4L9.96979 12.7C9.77447 12.5898 9.60593 12.4378 9.47629 12.2548C9.34664 12.0719 9.2591 11.8625 9.21991 11.6417C9.18072 11.4209 9.19087 11.1942 9.24963 10.9778C9.30839 10.7614 9.41431 10.5606 9.55978 10.39C10.6598 9.11995 10.7298 6.81994 10.6698 5.56994C10.6407 4.8047 10.3415 4.0745 9.82532 3.50885C9.30909 2.9432 8.60917 2.5787 7.84976 2.47998H7.52979H7.16977C6.41036 2.5787 5.71047 2.9432 5.19424 3.50885C4.67802 4.0745 4.37883 4.8047 4.34976 5.56994C4.28976 6.81994 4.34978 9.11995 5.45978 10.39C5.60525 10.5606 5.71117 10.7614 5.76993 10.9778C5.82869 11.1942 5.83884 11.4209 5.79965 11.6417C5.76046 11.8625 5.67289 12.0719 5.54324 12.2548C5.4136 12.4378 5.24509 12.5898 5.04977 12.7L1.98978 14.43C1.84423 14.5093 1.7228 14.6263 1.63831 14.7689C1.55382 14.9115 1.5094 15.0743 1.50977 15.24V18.65H0.00976562V15.24C0.0105659 14.8085 0.125766 14.385 0.343597 14.0126C0.561429 13.6402 0.874121 13.3322 1.24979 13.12L4.30978 11.39C2.95978 9.78995 2.76976 7.3 2.84976 5.5C2.89725 4.37813 3.33983 3.30912 4.09924 2.48199C4.85866 1.65487 5.88604 1.12287 6.99979 0.979979C7.17626 0.968262 7.35331 0.968262 7.52979 0.979979C7.69292 0.968629 7.85664 0.968629 8.01978 0.979979C9.13352 1.12287 10.1609 1.65487 10.9203 2.48199C11.6797 3.30912 12.1223 4.37813 12.1698 5.5C12.2498 7.3 12.0598 9.78999 10.6998 11.37L13.7698 13.12C13.9912 13.2469 14.1905 13.409 14.3598 13.6V13.6Z"
            fill={color}
          />
          <path
            d="M17.9301 19.89L12.8801 24.94L8.73011 20.78L7.86012 19.92C7.57035 19.6513 7.33771 19.327 7.1761 18.9664C7.01448 18.6059 6.92721 18.2164 6.9195 17.8214C6.9118 17.4263 6.98381 17.0337 7.13124 16.6671C7.27866 16.3005 7.4985 15.9674 7.77757 15.6876C8.05664 15.4079 8.38922 15.1873 8.75547 15.0389C9.12173 14.8906 9.51414 14.8176 9.90922 14.8244C10.3043 14.8312 10.694 14.9175 11.0549 15.0782C11.4159 15.239 11.7408 15.4708 12.0101 15.76L12.8801 16.63L13.7701 15.74C13.9159 15.5934 14.0768 15.4627 14.2501 15.35C14.475 15.2005 14.721 15.0859 14.9801 15.01C15.4994 14.8483 16.0528 14.8308 16.5812 14.9594C17.1097 15.088 17.5932 15.3578 17.9801 15.74C18.5082 16.3048 18.7979 17.0518 18.7886 17.825C18.7792 18.5982 18.4717 19.338 17.9301 19.89V19.89Z"
            fill={color}
          />
        </svg>
      ) : (
        <svg fill="none" viewBox="0 0 24 24">
          <path
            d="M11.318 11.069c2.219 0 4.017-2.03 4.017-4.535C15.335 4.03 13.537 2 11.318 2 9.1 2 7.301 4.03 7.301 6.534c0 2.505 1.798 4.535 4.017 4.535ZM1 20.265c2.413-2.776 6.125-4.534 10.318-4.534s7.894 1.758 10.319 4.534"
            stroke={color}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.716 14.007c-.668 0-1.241.29-1.698.74a2.278 2.278 0 0 0-1.698-.74c-1.289 0-2.366 1.064-2.366 2.417 0 2.545 3.478 5.576 4.064 5.576.585 0 4.064-3.03 4.064-5.576 0-1.307-1.078-2.417-2.366-2.417Z"
            fill="#FF7E9D"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default FavoriteAdvisorsIcon
