import React from "react"

import FavoriteExpertsConnected from "./FavoriteExperts.connected"
import RecentExpertsConnected from "./RecentExperts.connected"
import {
  FavoritesEmptyState,
  RecentsEmptyState,
  ListErrorState,
} from "./MyExpertsEmptyStates"
import { MY_EXPERTS_PAGE_COUNT } from "./constants"
import { TListing, IExpertsTabList } from "./types"

function getMyExpertsTabsList(
  isExtended: boolean,
  label: string,
  placeholderSize?: number
): Array<IExpertsTabList> {
  return [
    {
      label,
      value: "favorite",
      content: (
        <FavoriteExpertsConnected
          listing="favorite"
          withProducts
          withFavouriteButton
          withPagination
          size={MY_EXPERTS_PAGE_COUNT}
          placeholderSize={placeholderSize}
          emptyState={<FavoritesEmptyState isExtended={isExtended} />}
          errorState={<ListErrorState isExtended={isExtended} />}
          isExtended={isExtended}
        />
      ),
    },
    {
      label: "Kürzlich angesehen",
      shortLabel: "Kürzlich angesehen",
      value: "recent",
      content: (
        <RecentExpertsConnected
          listing="recent"
          withProducts
          withFavouriteButton
          withUndo={false}
          withPagination
          size={MY_EXPERTS_PAGE_COUNT}
          placeholderSize={placeholderSize}
          emptyState={<RecentsEmptyState isExtended={isExtended} />}
          errorState={<ListErrorState isExtended={isExtended} />}
          isExtended={isExtended}
        />
      ),
    },
  ]
}

function getRecommendationTabsList(
  _isExtended: boolean,
  _placeholderSize?: number
): Array<IExpertsTabList> {
  return [
    {
      label: "Für Dich",
      value: "forYou",
      content: <>Not implemented</>,
    },
    {
      label: "Beliebt",
      value: "popular",
      content: <>Not implemented</>,
    },
    {
      label: "Neu",
      value: "new",
      content: <>Not implemented</>,
    },
  ]
}

export function getTaBarItems(
  listing: TListing,
  isExtended: boolean,
  label: string,
  placeholderSize?: number
): Array<IExpertsTabList> {
  switch (listing) {
    case "recommendations":
      return getRecommendationTabsList(isExtended, placeholderSize)
    case "my-advisors":
      return getMyExpertsTabsList(isExtended, label, placeholderSize)
    default:
      throw new Error("Wrong listing name provided")
  }
}
