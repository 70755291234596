import React, { useCallback } from "react"
import clsx from "clsx"

import { useRuntimeConfig } from "../../hooks/runtimeConfig"
import { mapExpertsToClientTrackingTrack } from "../Experts/utils"
import ExpertsList from "./ExpertsList"
import { IMyExpertListProps } from "./types"
import { Box } from "../_common"
import OneExpertListContainer from "../../containers/OneExpertListContainer"
import useMyFavorites from "../../hooks/myFavorites/useMyFavorites"
import classes from "./FavoriteExperts.connected.module.scss"

const listingName = "Expert List Navbar Favorite"

const FavoriteExpertsConnected: React.FC<IMyExpertListProps> = ({
  listing,
  emptyState,
  errorState,
  isExtended,
  placeholderSize,
  withProducts,
  withFavouriteButton,
}) => {
  const { getRuntimeConfigByKey } = useRuntimeConfig()
  const { experts, favoritesError, favoritesLoading } = useMyFavorites()

  const isOneExpert = experts.length === 1

  const getClientTrackingProperties = useCallback(
    (experts: any) => ({
      products: mapExpertsToClientTrackingTrack(
        experts,
        getRuntimeConfigByKey("MANDATOR_NUMBER"),
        { tag: true }
      ).map((expert) => ({
        ...expert,
        favourited: true,
      })),
      list_id: listingName,
    }),
    []
  )

  return (
    <Box className={clsx(isOneExpert && classes.root)}>
      <ExpertsList
        getClientTrackingProperties={getClientTrackingProperties}
        listing={listing}
        // decided to show all experts for now
        loadMore={() => {}}
        currentPageNumber={1}
        expertsListing={{ content: experts, isLast: true }}
        loading={favoritesLoading}
        withRating={false}
        withPagination={false}
        placeholderSize={placeholderSize}
        emptyState={!favoritesError && emptyState}
        errorState={favoritesError && errorState}
        isExtended={isExtended}
        listingName={listingName}
        withProducts={withProducts}
        withFavouriteButton={withFavouriteButton}
      />

      {isOneExpert && <OneExpertListContainer isExtended={isExtended} />}
    </Box>
  )
}

export default FavoriteExpertsConnected
