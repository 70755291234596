import { createContext } from "react"

import { IMyExpertsContext } from "./types"

const MyFavoritesContext = createContext<IMyExpertsContext>({
  checkIsFavourite(_id: number): boolean {
    return false
  },
  experts: [],
  expertsIds: [],
  updateFavorites: () => Promise.resolve(),
  recentAction: null,
  resetRecentAction: () => {},
  favoritesError: false,
  favoritesLoading: false,
})

export default MyFavoritesContext
