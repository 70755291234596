import { useMemo, useEffect } from "react"

import { AxiosError } from "axios"
import useSWR, { BareFetcher } from "swr"
import useInterval from "../../../hooks/useInterval"
import { IAdvisorStatus } from "../../../types"
import { FavoriteExpertsStatusesCount } from "../types"
import {
  countFavoriteExpertsStatuses,
  getFavoriteExpertsCommonStatus,
} from "../utils"
import { useRuntimeConfig } from "../../../hooks/runtimeConfig"
import { fetchWithConfig, SWR_KEYS } from "../../../utils/rest"
import useMyFavorites from "../../../hooks/myFavorites/useMyFavorites"
import { IAdvisorOnlineStatusFetchResult } from "../../../ui-lib/Experts/types"

interface IUseFetchFavoritesList {
  countStatuses: FavoriteExpertsStatusesCount | undefined
  status: IAdvisorStatus | undefined
  loading: boolean
  error: AxiosError | undefined
}

export const useFetchFavoriteExpertsStatus = (): IUseFetchFavoritesList => {
  const { expertsIds } = useMyFavorites()
  const { getRuntimeConfigByKey, getRuntimeConfig } = useRuntimeConfig()

  const { data, error, mutate, isValidating, isLoading } = useSWR<
    IAdvisorOnlineStatusFetchResult
  >(expertsIds.length === 0 ? null : SWR_KEYS.EXPERT_ONLINE_STATUSES, ((
    key,
    { headers }
  ) => {
    return fetchWithConfig(
      {
        url: key,
        data: {
          ids: expertsIds,
          count: expertsIds.length,
        },
        headers,
        method: "POST",
      },
      getRuntimeConfig,
      "advice"
    )
  }) as BareFetcher<IAdvisorOnlineStatusFetchResult>)

  const expertsIdsToCheck = JSON.stringify(expertsIds)

  useEffect(() => {
    mutate()
  }, [expertsIdsToCheck, mutate])

  useInterval(() => {
    if (!isValidating) {
      mutate()
    }
  }, getRuntimeConfigByKey("EXPERT_ONLINE_STATUS_INTERVAL_MS"))

  const expertStatuses = useMemo(() => (!isValidating && data?.list) || [], [
    data?.list,
    isValidating,
  ])

  const { countStatuses, status } = useMemo(() => {
    const countStatuses = countFavoriteExpertsStatuses(expertStatuses)
    const status = getFavoriteExpertsCommonStatus(countStatuses)

    return { countStatuses, status }
  }, [expertStatuses])

  return {
    countStatuses,
    status,
    loading: isLoading || isValidating,
    error,
  }
}
