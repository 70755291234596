"use client"

import React, { useState, useEffect } from "react"
import { useTranslations } from "next-intl"
import clsx from "clsx"
import Image from "next/image"
import favoritesImage from "./images/favorites.svg"
import recentImage from "./images/recent.svg"

import {
  AdviqoButton,
  Box,
  Typography,
  FadeTransition,
  useMediaQuery,
} from "../_common"
import ErrorCircleIcon from "../_common/QuesticoIcon/ErrorCircleIcon"
import AnimatedIcon, {
  favoritesConfig,
  favoritesArrowConfig,
  recentsConfig,
} from "../_common/AnimatedIcon"
import { customBreakpoints } from "../../themes/questico"
import { useCheckBrand } from "../_common/QuesticoIcon/utils/useCheckBrand"
import classes from "./MyExpertsEmptyStates.module.scss"

const DELAY = 3 * 1000
const FAV_ANIMATION_DURATION = 10.2 * 1000
const ARROW_ANIMATION_DURATION = 3.72 * 1000
const ARROW_HEIGHT = 90
const favDelay = FAV_ANIMATION_DURATION + ARROW_ANIMATION_DURATION + DELAY

export const FavoritesEmptyState: React.FC<{
  isExtended: boolean
}> = ({ isExtended }) => {
  const isExtraSmallScreen = useMediaQuery(customBreakpoints.sDown)
  const translate = useTranslations()
  const [showArrow, setShowArrow] = useState(false)
  // const shouldRenderArrow = !hasRecommendationsPath(pathname) && !isExtended
  // False due to this comment https://adviqo.atlassian.net/browse/FEM-440?focusedCommentId=173082
  const shouldRenderArrow = false
  const { isQuesticoBrand } = useCheckBrand()
  const rootClasses = clsx(classes.rootEmpty, {
    [classes.isExtendedEmpty]: isExtended,
  })

  useEffect(() => {
    const timerID = window.setTimeout(() => {
      setShowArrow(true)
    }, FAV_ANIMATION_DURATION + 1000)

    return () => {
      if (timerID) window.clearTimeout(timerID)
    }
  }, [])

  return (
    <Box className={rootClasses} data-testid="favorites-empty-state">
      <Typography
        variant="h2"
        className={clsx(classes.titleEmpty, classes.textEmpty)}
      >
        {translate("emptyFavouritesTitle")}
      </Typography>
      <Typography
        className={clsx(
          classes.description,
          classes.textEmpty,
          classes.favOffset
        )}
      >
        {translate("emptyFavouritesDescription")}
      </Typography>
      {isQuesticoBrand ? (
        <AnimatedIcon
          type="favorites"
          config={favoritesConfig}
          width={isExtraSmallScreen ? 270 : 320}
          height={155}
          repeatAfter={favDelay}
          style={{
            marginBottom: isExtended || isExtraSmallScreen ? 0 : ARROW_HEIGHT,
          }}
        />
      ) : (
        <Box textAlign="center">
          <Image src={favoritesImage} alt="Favorites Image" />
        </Box>
      )}

      {shouldRenderArrow && (
        <FadeTransition show={showArrow}>
          <AnimatedIcon
            type="favorites-arrow"
            config={favoritesArrowConfig}
            width={isExtraSmallScreen ? 270 : 320}
            height={ARROW_HEIGHT}
            className={classes.arrow}
          />
        </FadeTransition>
      )}
    </Box>
  )
}

const RECENTS_ANIMATION_DURATION = 5 * 1000
const recentsDelay = RECENTS_ANIMATION_DURATION + DELAY

export const RecentsEmptyState: React.FC<{
  isExtended: boolean
}> = ({ isExtended }) => {
  const isExtraSmallScreen = useMediaQuery(customBreakpoints.sDown)
  const rootClasses = clsx(classes.root, {
    [classes.isExtended]: isExtended,
  })
  const translate = useTranslations()
  const { isQuesticoBrand } = useCheckBrand()

  return (
    <Box className={rootClasses} data-testid="favorites-empty-state">
      <Typography variant="h2" className={clsx(classes.title, classes.text)}>
        {translate("emptyRecentTitle")}
      </Typography>
      <Typography
        className={clsx(
          classes.description,
          classes.text,
          classes.recentsOffset
        )}
      >
        {translate("emptyRecentDescription")}
      </Typography>
      {isQuesticoBrand ? (
        <AnimatedIcon
          type="recents"
          config={recentsConfig}
          width={isExtraSmallScreen ? 200 : 320}
          height={isExtraSmallScreen ? 200 : 320}
          repeatAfter={recentsDelay}
        />
      ) : (
        <Box textAlign="center">
          <Image src={recentImage} alt="Recent Image" />
        </Box>
      )}
    </Box>
  )
}

export const RecommendedEmptyState: React.FC<{
  isExtended: boolean
}> = ({ isExtended }) => {
  const rootClasses = clsx(classes.root, {
    [classes.isExtended]: isExtended,
  })

  return (
    <Box className={rootClasses} data-testid="favorites-empty-state">
      <Box component="h2" className={clsx(classes.title, classes.row)}>
        Noch kein Lieblingsberater ausgewählt!
      </Box>
      <Box className={classes.row}>
        <Box component="p" className={classes.text}>
          Sobald du einen Berater über das Herzsymbol favorisierst, erscheint er
          hier.
        </Box>
        <Box component="p" className={classes.text}>
          Los geht’s! Klicke auf den unteren Button und finde zu jedem Thema den
          passenden Berater.
        </Box>
      </Box>
      <AdviqoButton
        to="/berater-finden"
        label="Berater finden"
        buttonClassName={clsx(classes.action, classes.row)}
        labelCentered
      />
    </Box>
  )
}

export const ListErrorState: React.FC<{
  isExtended: boolean
}> = ({ isExtended }) => {
  const rootClasses = clsx(classes.root, {
    [classes.isExtended]: isExtended,
  })

  return (
    <Box className={rootClasses}>
      <Box component="span" className={classes.icon}>
        <ErrorCircleIcon />
      </Box>
      <Box component="h2" className={clsx(classes.title, classes.titleError)}>
        Etwas ist schief gelaufen.
      </Box>
      <Box className={classes.row}>
        <Box component="p" className={classes.text}>
          Bitte versuche es später noch einmal.
        </Box>
      </Box>
    </Box>
  )
}
