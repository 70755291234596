import React from "react"
import clsx from "clsx"

import { Typography, Box, List, ListItem, AdviqoLink } from "../_common"
import { buildLinkUrl } from "../../utils/slugUtils"
import { IContentItem } from "../../graphql/contentful-schema"
import { isSlugOrArticleOrPage } from "../../utils/typeGuards"
import classes from "./OneExpertList.module.scss"

interface IOneExpertListProps {
  items: IContentItem[] | undefined
  isExtended?: boolean
}

const OneExpertList: React.FC<IOneExpertListProps> = ({
  items,
  isExtended,
}) => {
  return (
    <Box className={classes.root}>
      <Typography variant="h2" className={clsx(classes.title, classes.text)}>
        Nicht sicher, wen du hinzufügen sollst?
      </Typography>

      <Typography className={clsx(classes.description, classes.text)}>
        Lerne mehr Experten für Themen kennen, die dir wichtig sind:
      </Typography>

      {items !== undefined && (
        <List
          className={clsx(
            classes.categories,
            isExtended && classes.categoriesExtended
          )}
          disablePadding
        >
          {items.map(({ headline, image, sys, link }) => {
            let content: JSX.Element
            const text = (
              <Typography variant="h3" className={classes.categoryHeadline}>
                {headline}
              </Typography>
            )

            if (link && isSlugOrArticleOrPage(link)) {
              content = (
                <AdviqoLink to={buildLinkUrl(link)} className={classes.link}>
                  {text}
                </AdviqoLink>
              )
            } else {
              content = text
            }

            return (
              <ListItem
                className={classes.category}
                style={{ backgroundImage: `url(${image.url})` }}
                key={sys?.id}
                disableGutters
              >
                {content}
              </ListItem>
            )
          })}
        </List>
      )}
    </Box>
  )
}

export default OneExpertList
