import { BrandsEnum } from "../types"
import { IPublicRuntimeConfig } from "../utils/runtimeConfig"

const getIdsForBrand = (mandatorNo: BrandsEnum) => {
  switch (mandatorNo) {
    case BrandsEnum.QUESTICO:
      return {
        MY_DASHBOARD_CONFIG_ID: "1cHJb2TzdiqskPE5yVKKsp",
        AUTHENTICATION_CONFIG_ID: "4aXmCX0KYhzZkat77cXlr7",
        WIZARD_CONFIG_ID: "6NwHeqwLt3nxEY9bIpxPgL",
        WIZARD_ID: "3WoSjNN02k3O1kL4fuIYIH",
        MY_ADVISORS_CONFIG_ID: "4E5On3GvX5krMrAP590WHG",
        TOP_LEVEL_CONFIG_ID: "4RAePSl9CVXfFbp7bGZJtW",
        PAGE_NOT_FOUND_CONFIG_ID: "1CHFuoU4hCqX2t7h7kZgoD",
        INTRO_PROMOTION_OFFER_BAR_ID: "5g6N4U5snpcZR8DyHrZhdB",
        ONE_EXPERT_ITEM_LIST: "77sggEJAo9mWiZn2lINyky",
        AUTHENTICATION_PAGE_ID: "3FveHDXm2xQEkkJnTS3oaT",
        HOME_PAGE_ID: "3a0O47mfbU0vp1UzDzPsKd",
        MAGAZIN_PAGE_ID: "cf3b9a7a5908b88e8aaaa1cb",
        EXPERT_PROFILE_ENTITY_ID: "2McHuGSqEUFRNukfp7qY9e",
        EXPERT_ADS_MY_FAVOURITES_ID: "3Vn0enQp6dN3jkbhDn7ri2",
        EXPERT_ADS_MY_COUPONS_ID: "1ixtgoemkfcnnPi685XIhu",
      }

    case BrandsEnum.VIVERSUM_GERMANY:
      return {
        MY_DASHBOARD_CONFIG_ID: "6Ao88WJaV6zeqgBrROuIiV",
        AUTHENTICATION_CONFIG_ID: "eNHVUixGgB3Q05dBkxeUX",
        WIZARD_CONFIG_ID: "5We3RK74fTnQIL0QOV2Twu",
        WIZARD_ID: "4EZcfNJiZAPCHmNisopVLE",
        MY_ADVISORS_CONFIG_ID: "3rAE6SSt1fSkHPzOV0ANfg",
        TOP_LEVEL_CONFIG_ID: "20r0CUPJwqbOv0wdsTjpO6",
        PAGE_NOT_FOUND_CONFIG_ID: "4nFkollvNI4Ky5jbJNVnLz",
        INTRO_PROMOTION_OFFER_BAR_ID: "7qqY4KVNudL0nYDXRh8baV",
        ONE_EXPERT_ITEM_LIST: "50tPRTrZtFZJJBdniGmXjQ",
        AUTHENTICATION_PAGE_ID: "7aMEvN8kZ2H6s7zlUz94mQ",
        HOME_PAGE_ID: "484zmKZ6tedncqaRaaBlqE",
        MAGAZIN_PAGE_ID: "2aEvcQp91R9rOcp6SYhISL",
        EXPERT_PROFILE_ENTITY_ID: "60wVigwkwyGMSZUIeoZeQr",
        EXPERT_ADS_MY_FAVOURITES_ID: "61ntSyiemWqKCpGvjd3tRQ",
        EXPERT_ADS_MY_COUPONS_ID: "7miV2lCHAoei7jq34dnwA5",
      }
    // global space
    default:
      return {
        MY_DASHBOARD_CONFIG_ID: "1cHJb2TzdiqskPE5yVKKsp",
        AUTHENTICATION_CONFIG_ID: "4aXmCX0KYhzZkat77cXlr7",
        WIZARD_CONFIG_ID: "6NwHeqwLt3nxEY9bIpxPgL",
        WIZARD_ID: "3WoSjNN02k3O1kL4fuIYIH",
        MY_ADVISORS_CONFIG_ID: "4E5On3GvX5krMrAP590WHG",
        TOP_LEVEL_CONFIG_ID: "20r0CUPJwqbOv0wdsTjpO6",
        PAGE_NOT_FOUND_CONFIG_ID: "1CHFuoU4hCqX2t7h7kZgoD",
        INTRO_PROMOTION_OFFER_BAR_ID: "5g6N4U5snpcZR8DyHrZhdB",
        ONE_EXPERT_ITEM_LIST: "77sggEJAo9mWiZn2lINyky",
        AUTHENTICATION_PAGE_ID: "3FveHDXm2xQEkkJnTS3oaT",
        HOME_PAGE_ID: "499P0xyQtDoXcxnqBzooSh",
        MAGAZIN_PAGE_ID: "cf3b9a7a5908b88e8aaaa1cb",
        EXPERT_PROFILE_ENTITY_ID: "2McHuGSqEUFRNukfp7qY9e",
        EXPERT_ADS_MY_FAVOURITES_ID: "3Vn0enQp6dN3jkbhDn7ri2",
        EXPERT_ADS_MY_COUPONS_ID: "1ixtgoemkfcnnPi685XIhu",
      }
  }
}

export function getContentfulEntriesIDs(
  getRuntimeConfig: () => IPublicRuntimeConfig
): Record<keyof ReturnType<typeof getIdsForBrand>, string> {
  const { MANDATOR_NUMBER } = getRuntimeConfig()

  return getIdsForBrand(MANDATOR_NUMBER)
}
